import React from 'react';
import MediaAboutUsIllustration from '../../assets/svg/media-about-us-illustration.svg';

const MediaAboutUs = () => (
  <section className="media-about-us">
    <div className="content">
      <h2 className="media-about-us__heading">
        Медіа <br />
        про нас
      </h2>
      <MediaAboutUsIllustration className="media-about-us__illustration" />
      <div className="media-about-us__news-container">
        <div className="media-about-us__post">
          <h3 className="media-about-us__post-heading">Українська правда</h3>
          <p className="media-about-us__post-lead">
            "Ти тут не жінка, бо війна не має статі": Маргарита Рівчаченко про службу в теробороні
            та допомогу армії
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://life.pravda.com.ua/society/2022/03/8/247712/"
            className="media-about-us__post-link"
          >
            читати
          </a>
        </div>
        <div className="media-about-us__post">
          <h3 className="media-about-us__post-heading">Громадське</h3>
          <p className="media-about-us__post-lead">
            Поклав мандат, пішов на фронт. Де нині народні депутати
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://hromadske.ua/posts/poklav-mandat-pishov-na-front-de-nini-narodni-deputati"
            className="media-about-us__post-link"
          >
            читати
          </a>
        </div>
        <div className="media-about-us__post">
          <h3 className="media-about-us__post-heading">Хмарочос</h3>
          <p className="media-about-us__post-lead">
            Волонтери «Солом’янські котики» створили велозагін для доставки продуктів і ліків
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://hmarochos.kiev.ua/2022/03/08/volontery-solomyanski-kotyky-stvoryly-velozagin-dlya-dostavky-produktiv-i-likiv/"
            className="media-about-us__post-link"
          >
            читати
          </a>
        </div>
        <div className="media-about-us__post">
          <h3 className="media-about-us__post-heading">БЖ</h3>
          <p className="media-about-us__post-lead">
            Активісти розробили план захисту вітражів Київського фунікулеру: як допомогти ініціативі
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://bzh.life/ua/gorod/akivisti-rozrobili-plan-zahistu-vitrazhiv-kiyivskogo-funikuleru-yak-dopomogti-inicziativi/"
            className="media-about-us__post-link"
          >
            читати
          </a>
        </div>
        <div className="media-about-us__post">
          <h3 className="media-about-us__post-heading">Голос України</h3>
          <p className="media-about-us__post-lead">«Солом'янські котики»: крок до перемоги.</p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/GolosUkrainy/posts/2992019401128571"
            className="media-about-us__post-link"
          >
            читати
          </a>
        </div>
        <div className="media-about-us__post">
          <h3 className="media-about-us__post-heading">Хмарочос</h3>
          <p className="media-about-us__post-lead">
            Рятують життя, вивозять з гарячих точок, доставляють допомогу. Що роблять київські
            урбаністи під час війни?
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://hmarochos.kiev.ua/2022/03/30/ryatuyut-zhyttya-vyvozyat-z-garyachyh-tochok-dostavlyayut-dopomogu-shho-roblyat-kyyivski-urbanisty-pid-chas-vijny/"
            className="media-about-us__post-link"
          >
            читати
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default MediaAboutUs;
