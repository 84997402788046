import React from 'react';
import HowToHelpIllustration from '../../assets/svg/how-to-help-illustration.svg';
import MailIcon from '../../assets/svg/mail-icon.svg';
import SocialIcons from '../../assets/svg/social-icons.svg';

const HowToHelp = () => (
  <section className="how-to-help" id="how-to-help">
    <div className="content">
      <h2 className="how-to-help__heading">Як нам допомогти?</h2>
      <HowToHelpIllustration className="how-to-help__illustration" />
      <div className="how-to-help__columns">
        <p className="how-to-help__donate-description how-to-help__item">
          Відправити кошти, скориставшись будь-якими <br />
          цими реквізитами:
        </p>
        <p className="how-to-help__donate-addresses how-to-help__item">
          IBAN БО БФ Солом’янськi котики (гривня): <br />
          UA283052990000026003045019432 IBAN <br />
          БО БФ Солом’янськi котики (долар): <br />
          UA593052990000026002025031282 <br />
          Монобанк: <br />
          <a
            href="https://send.monobank.ua/jar/79BDi83S6w"
            style={{wordBreak: 'break-word'}}
            target="_blank"
          >
            https://send.monobank.ua/jar/79BDi83S6w
          </a>
          <br />
          Приватбанк: 5169335100164686
          <br />
          PayPal: ae.dexter26@gmail.com
        </p>
        {/*<p className="how-to-help__donate-addresses-owner how-to-help__item">*/}
        {/*  Власник рахунків — Андрій Єрофєєв*/}
        {/*</p>*/}
        <p className="how-to-help__phone-description how-to-help__item">
          Якщо ви хочете разом зробити проєкт, допомогти нашому фонду чи знаєте інші способи, як
          наблизити перемогу, пишіть на нашу електронну пошту:
        </p>
        <div className="how-to-help__phone-container how-to-help__item">
          <div className="how-to-help__phone-text">kitty.soloma@gmail.com</div>
          <a className="how-to-help__phone-button-link" href="mailto:kitty.soloma@gmail.com">
            <MailIcon className="how-to-help__mail-icon" />
          </a>
        </div>

        <p className="how-to-help__crypto-description how-to-help__item">
          Надіслати криптовалюту на будь-який з наших криптогаманців:
        </p>
        <div className="how-to-help__crypto-addresses how-to-help__item">
          Ethereum: <br />
          0x1f4c09CE996b14d361393e73Bd45C001469e5109 <br />
          Bitcoin: <br />
          bc1q0rg3pfheqzfpe6zzzj7gzx7xr35y9yf3neujp7 <br />
          USDT (Tether ERC20): <br />
          0x1f4c09CE996b14d361393e73Bd45C001469e5109 <br />
        </div>
        <p className="how-to-help__share-description how-to-help__item">
          Поширюйте наші пости та <br />
          розповідайте про нас друзям
        </p>

        <div className="how-to-help__subscribe-buttons-container how-to-help__item">
          <SocialIcons className="how-to-help__subscribe-buttons" />
          <a
            className="how-to-help__subscribe-link how-to-help__subscribe-link_telegram"
            href="https://t.me/kitty_soloma"
            target="_blank"
            rel="noreferrer"
          />
          <a
            className="how-to-help__subscribe-link how-to-help__subscribe-link_facebook"
            href="https://www.facebook.com/kittysoloma"
            target="_blank"
            rel="noreferrer"
          />
          <a
            className="how-to-help__subscribe-link how-to-help__subscribe-link_instagram"
            href="https://www.instagram.com/kitty_soloma"
            target="_blank"
            rel="noreferrer"
          />
        </div>
      </div>
    </div>
  </section>
);

export default HowToHelp;
