import React from 'react';
import {Layout} from '../components/Layout';
import Hero from '../components/Main/Hero';
import WhoWeAre from '../components/Main/WhoWeAre';
import HowToHelp from '../components/Main/HowToHelp';
import MediaAboutUs from '../components/Main/MediaAboutUs';

function Index() {
  return (
    <Layout
      seo={{
        title: 'Солом’янські котики',
        description: `Благодійний фонд, який допомагає захисникам, рятувальникам і цивільним. Фокус на тактичній медицині та розмінуванні. Створюємо проєкти, які наближають перемогу України.`,
      }}
      language={'ua'}
    >
      <Hero />
      <WhoWeAre />
      <HowToHelp />
      <MediaAboutUs />
    </Layout>
  );
}

export default Index;
