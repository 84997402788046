import React from 'react';
import {Link} from 'gatsby';
import HeroIllustration from '../../assets/svg/hero-illustration.svg';

const Hero = () => (
  <section className="hero-section">
    <div className="content">
      <h1 className="hero-section__heading">Ми Солом’янські котики</h1>
      <HeroIllustration className="hero__illustration" />
      <p className="hero-section__lead">
        Благодійний фонд, який допомагає захисникам, рятувальникам і цивільним. Фокус на тактичній
        медицині та розмінуванні. Створюємо проєкти, які наближають перемогу України.
      </p>
      <Link to="#how-to-help" className="button button_black hero-section__button">
        Підтримати
      </Link>
    </div>
  </section>
);

export default Hero;
