import React from 'react';
import WhoWeAreIllustration from '../../assets/svg/who-we-are-illustration.svg';

const WhoWeAre = () => (
  <section className="who-we-are" id="who-we-are">
    <div className="content">
      <h2 className="who-we-are__heading">За перші 50 днів війни:</h2>
      <div className="who-we-are__counter-list">
        <div className="who-we-are__counter-list-item">
          <div className="who-we-are__counter">1920</div>
          <div className="who-we-are__counter-placeholder">запитів допомоги виконали </div>
        </div>
        <div className="who-we-are__counter-list-item">
          <div className="who-we-are__counter">737</div>
          <div className="who-we-are__counter-placeholder">
            продуктових наборів передали для людей з потребами{' '}
          </div>
        </div>
        <div className="who-we-are__counter-list-item">
          <div className="who-we-are__counter">250</div>
          <div className="who-we-are__counter-placeholder">котиків волонтерили у штабі </div>
        </div>
        <div className="who-we-are__counter-list-item">
          <div className="who-we-are__counter">465</div>
          <div className="who-we-are__counter-placeholder">
            захисників пройшли наші тренінги з тактичної медицини
          </div>
        </div>
        <div className="who-we-are__counter-list-item">
          <div className="who-we-are__counter">106</div>
          <div className="who-we-are__counter-placeholder">аптечок передали захисникам </div>
        </div>
      </div>
      <div className="who-we-are__illustration-and-activity">
        <WhoWeAreIllustration className="who-we-are__illustration" />
        <div className="who-we-are__activity-list">
          <p className="who-we-are__activity-paragraph">
            Передали гуманітарної допомоги та обладнання для захисників більш ніж на
          </p>
          <h3 className="who-we-are__activity-heading">40 000 000 грн</h3>
          <p className="who-we-are__activity-paragraph">Загалом зібрали грошей:</p>
          <h3 className="who-we-are__activity-heading">5 799 049,82 грн</h3>
        </div>
      </div>
    </div>
  </section>
);

export default WhoWeAre;
